import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) { }
    register(name: string, email: string, password: string, cpassword: any, phoneNumber: any, phone: any, company: any, address1: any, address2: any, country: any, state
        : any, city: any, refrral: any, plansId = "") {
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/user/signup`, { name: name, email: email, password: password, cpassword: cpassword, company: company, phoneNumber: phoneNumber, phone: phone, address1: address1, address2: address2, country: country, state: state, city: city, refrral: refrral, plansId: plansId }).pipe(map(user => {
            if (user.status.code == 0) {
                localStorage.setItem(`${environment.appName}` + '_user', user.data.access_token);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.user.status);
                localStorage.setItem(`${environment.appName}` + '_domain', '');
            }

            return user;
        }));
    }

    login(email: string, password: string) {

        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/user/login`, { userID: email, password: password }).pipe(map(user => {
            // login successful if there's a jwt token in the response
            // return false;
            if (user.status.code == 0) {
                localStorage.setItem(`${environment.appName}` + '_user_obj', JSON.stringify(user.user));
                localStorage.setItem(`${environment.appName}` + '_user_room', JSON.stringify(user.room));
                localStorage.setItem(`${environment.appName}` + '_user_room_details', user.user.room_details);
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(`${environment.appName}` + '_user', user.data.accessToken);
                localStorage.setItem(`${environment.appName}` + '_adminName', user.user.name);
                localStorage.setItem(`${environment.appName}` + '_adminId', user.user._id);
                localStorage.setItem(`${environment.appName}` + '_userId', user.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.user.roleId);
                localStorage.setItem(`${environment.appName}` + '_status', user.user.status);
                localStorage.setItem(`${environment.appName}` + '_domain', user.user.shop ? user.user.shop.domain : '');



            }

            return user;
        }));

    }

    getUser(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/get-user`).pipe(map(user => user));
    }


    logout() {
        // remove user from local storage to log user out
        // localStorage.removeItem(`${environment.appName}` + 'vendor_user');
        // localStorage.removeItem(`${environment.appName}` + '_role');
        localStorage.removeItem(`${environment.appName}` + '_user');
        localStorage.removeItem(`${environment.appName}` + '_userid');
        localStorage.removeItem(`${environment.appName}` + '_role');
        localStorage.removeItem(`${environment.appName}` + '_ins_Id');
        // localStorage.removeItem(`${environment.appName}` + '_adminName');
        // localStorage.removeItem(`${environment.appName}` + '_adminId');
        // localStorage.removeItem(`${environment.appName}` + '_status');
        // localStorage.removeItem(`${environment.appName}` + '_domain');
        // localStorage.removeItem(`${environment.appName}` + '_user_room_details');
        // localStorage.removeItem(`${environment.appName}` + '_userId');


    }


    sendOtp(phoneno_or_email: any) {
        const formData: FormData = new FormData();
        formData.append('phoneno_or_email', phoneno_or_email);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/send-otp`, formData).pipe(map(user => {
            return user;
        }));
    }

    verifyOtp(phoneno_or_email: any, otp: any) {
        var formData = {
            'phoneno_or_email': phoneno_or_email,
            'otp': otp
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/verify-otp`, formData).pipe(map(user => {
      
            if (user.status.code == 0) {
                localStorage.setItem(`${environment.appName}` + '_user', user.data.token);
                localStorage.setItem(`${environment.appName}` + '_userid', user.data.user._id);
                localStorage.setItem(`${environment.appName}` + '_role', user.data.user.role_id);
                localStorage.setItem(`${environment.appName}` + '_ins_Id', user.data.user.institution_id);
                localStorage.setItem(`${environment.appName}` + '_fullName', user.data.user.full_name);
               
                localStorage.setItem(`${environment.appName}` + 'eventId', user.data.user.eventId);
                
            }
            return user;
        }));
    }

    public isAuth() {
        if (localStorage.getItem(`${environment.appName}` + '_user')) {
            return true;
        } else {
            return false;
        }
    }


}